<template>
  <ul class="right-list-container">
    <li
      v-for="(item,i) in list"
      :key="i"
    >
      <span
        @click="handleClick(item)"
        :class="{ active : item.isSelect }"
      >{{ item.name }}
      <span v-if="item.articleCount" class="article">{{ item.articleCount }}篇</span>
    </span>
      <!-- 显示当前组件 -->
      <RightList
        :list="item.children"
        @select="handleClick"
      ></RightList>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'RightList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick (item) {
      if(!item.isSelect){
        this.$emit('select', item)
      }
    }
  }
}
</script>
  
<style lang="less" scoped>
@import url("~@/styles/val.less");
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  .right-list-container {
    margin-left: 1em;
  }
  li {
    font-size: 14px;
    padding: 8px 0;
    cursor: pointer;
    .active {
      color: @warn;
      font-weight: bold;
    }
    .article{
      margin-left: 5px;
    }
  }
}
</style>