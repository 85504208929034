import request from './request'

export async function getBlogs(page = 1, limit = 10, categoryid = -1) {
  return await request.get('/api/blog', {
    params: {
      page,
      limit,
      categoryid,
    },
  })
}
/**
 * 获取文章分类
 */
export async function getBlogTypes() {
  return await request.get(`/api/blogtype`)
}
/**
 * 获取文章详情
 */
export async function getBlog(id) {
  return await request.get(`/api/blog/${id}`)
}
/** 
 * 提交评论
*/
export async function postComment(comment) {
  return await request.post('/api/comment', comment)
}
/**
 * 获取评论
 */
export async function getComments(blogid, page = 1, limit = 10) {
  return await request.get('/api/comment', {
    params: {
      blogid,
      page,
      limit,
    },
  })
}