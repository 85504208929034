<template>
  <div id="app">
    <Layout>
      <template #left>
        <div class="aside">
          <SiteAside />
        </div>
      </template>
      <router-view />
    </Layout>
  </div>
  </template>

<script>
import Layout from './components/Layout'
import SiteAside from './components/SiteAside'
export default {
  name: 'App',
  components: {
    Layout,
    SiteAside
  },
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
@import url(~@/styles/mixin.less);
#app {
  .self-fill();
}
.aside {
  width: 250px;
  height: 100%;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
