// message.js
import Vue from 'vue'
import MessageContainer from '@/components/MessageContainer'

const MessageConstructor = Vue.extend(MessageContainer)

let instance
let messageQueue = []

const initInstance = () => {
  instance = new MessageConstructor({
    el: document.createElement('div')
  })
  document.body.appendChild(instance.$el)
}

const Message = (content, duration = 3000, type = 'success') => {
  if (!instance) {
    initInstance()
  }

  const msg = {
    id: `msg_${new Date().getTime()}`,
    content,
    duration,
    type,
    createTime: new Date().getTime()
  }

  messageQueue.push(msg)
  instance.addMessage(msg)
}

export default Message