import getComponentRootDom from './getComponentRootDom'
import Icon from '@/components/Icon'
import styles from './showMessage.module.less'

/**
 * @description: 弹出消息提示
 * @param {String} content 消息内容
 * @param {String} type 消息类型 info error success warn
 * @param {HTMLElement} container 容器
 * @param {Number} duration 持续时间
 */
export default function showMessage (
  options = {}
) {
  const {
    content = '',
    type = 'info',
    container = document.body,
    duration = 2000
  } = options
  const div = document.createElement('div')
  const iconDom = getComponentRootDom(Icon, {
    type,
  })
  iconDom.classList.add(styles.icon)
  div.innerHTML = `<span style="vertical-align: bottom">${iconDom.outerHTML}</span><div>${content}</div>`
  //容器的position是否改变
  if (container !== document.body && getComputedStyle(container).position === 'static'){
    container.style.position = 'relative'
  }
  div.className = `${styles.message} ${styles['message-' + type]}`
  container.appendChild(div)
  div.clientHeight
  div.style.opacity = 1
  div.style.transform = `translate(-50%, -50%)`
  setTimeout(() => {
    div.style.opacity = 0
    div.style.transform = `translate(-50%, -50%) translateY(-20px)`
    div.addEventListener(
      'transitionend',
      function () {
        div.remove()
        //运行回调函数
        options.callback && options.callback()
      },
      { once: true }
    )
  }, duration)
}
