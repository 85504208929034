<template>
  <div class="blog-detail-container">
    <h1>{{ blog.title }}</h1>
    <div class="aside">
      <span>日期：{{ formDate(blog.createDate) }}</span>
      <span>浏览：{{ blog.sanNumber }}</span>
      <a href="#data-from-container">评论: {{ blog.commentNumber }}</a>
      <RouterLink
       :to="{ name: '文章分类', params: { categoryId: blog.category.id } }"
      >{{ blog.category.name }}</RouterLink>
    </div>
    <div class="markdown-body" v-html="blog.htmlContent"></div>
  </div>
</template>

<script>
import utils from '@/utils'
import '@/styles/markdown.css'
import 'highlight.js/styles/github.css'
  export default {
    props: {
      blog: {
        type: Object,
        required: true
      }
    },
    methods: {
      formDate: utils.formDate
    }
  }
</script>

<style lang="less" scoped>
@import url("~@/styles/val.less");
.aside {
  margin-top: 10px;
  color:@gray;
  span,a {
    margin-right: 10px;
  }
}
.markdown-body{
  padding: 2em 0;
}
</style>