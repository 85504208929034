<template>
  <div
    v-size-ob="handleChange"
    class="XScroll-container"
  >
    <div class="v-scroll">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      s: {
        w: 0,
        h: 0
      }
    }
  },
  methods: {
    handleChange (size) {
      this.s = {
        w: size.height,
        h: size.width
      }
    },
  },
  directives: {
    'size-ob': {
      bind (el, binding) {
        // 初始化时获取元素尺寸
        binding.value({
          width: el.offsetWidth,
          height: el.offsetHeight
        })

        // 创建 ResizeObserver 实例
        const observer = new ResizeObserver(([entry]) => {
          binding.value({
            width: entry.contentRect.width,
            height: entry.contentRect.height
          })
        })

        // 观察元素尺寸变化
        observer.observe(el)

        // 在指令解绑时停止观察
        el._observer = observer
      },
      unbind (el) {
        if (el._observer) {
          el._observer.disconnect()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.XScroll-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .v-scroll {
    outline: 5px solid #7985ec;
  width: calc(v-bind("s.h") * 1px);
  height: calc(v-bind("s.w") * 1px);
  position: relative;
  overflow: auto;
  transform-origin: 0 0;
  transform: translateY(calc(v-bind("s.h") * 1px)) rotate(-90deg);
  }
  .content {
    outline: 5px solid #f1ac6a;
  height: calc(v-bind("s.h") * 1px);
  position: absolute;
  left: 100%;
  transform-origin: 0 0;
  transform: rotate(90deg);
  }
}
</style>