<template>
  <div class="Avatar-container">
    <img
      class="avatar-img"
      :src="url"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    url: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
  },
}
</script>

<style scoped>
.avatar-img {
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
  object-fit: cover;
  overflow: hidden;
  transition: 0.3s;
  width: 100px;
  height: 100px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}
</style>
