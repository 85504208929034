import styles from './loading.module.less';
function getLoadingDom(el) {
  return el.querySelector('[data-role=loading]')
}
const loadingTemplate = `
  <div class="${styles['loading-content']}">
    <div class="${styles['loading-icon']}"></div>
    <div class="${styles['loading-text']}">加载中...</div>
  </div>
`;
export default function(el, binding, vnode) {
  // Create a new loading element
  const loadingElement = document.createElement('div');
  loadingElement.classList.add(styles.loading);
  loadingElement.dataset.role='loading';
  
  loadingElement.innerHTML = loadingTemplate;

  const curLoading = getLoadingDom(el);
  if(binding.value) {
    if(!curLoading) {
      el.appendChild(loadingElement);
    }
  } else {
    curLoading && curLoading.remove();
  }
  
};
