<template>
  <div class="Contact-container">
    <a
      v-for="item in ContactData"
      :key="item.id"
      :href="item.link"
      class="Contact-item"
      :target="item.type === 'weixin' ? '_self' : '_blank'"
    >
      <Icon :type="item.type" />
      <label>{{ item.text }}</label>
      <div
        v-if="item.type === 'weixin'"
        class="Contact-ewm"
      >
        <div>
          <img
            :src="item.imgUrl"
            alt="微信二维码"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  data () {
    return {
      ContactData: [
        {
          id: 1,
          type: 'github',
          text: 'GitHub',
          link: '',
        },
        {
          id: 2,
          type: 'mail',
          text: '邮箱',
          link: 'mailto:1402946773.qq.com',
        },
        {
          id: 3,
          type: 'qq',
          text: 'QQ',
          link: 'http://wpa.qq.com/msgrd?v=3&uin=123456789&site=qq&menu=yes',
        },
        {
          id: 4,
          type: 'weixin',
          text: '微信',
          link: 'javascript:;',
          imgUrl: 'https://picsum.photos/200/200',
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import url(~@/styles/val.less);
.Contact-container {
  color: @lightWords;
  .Contact-item {
    position: relative;
    padding: 0 20px;
    height: 40px;
    display: flex;
    line-height: 40px;
    font-size: 16px;
    &:hover {
      color: @primary;
      .Contact-ewm {
        transform: scaleY(1);
      }
    }
  }
  label {
    margin-left: 10px;
  }
  .Contact-ewm {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: auto;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: transform 0.5s;
    display: flex;
    justify-content: center;
    div{
      padding: 15px;
      background: #fff;
      border-radius: 4px;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      border: 10px solid transparent;
      border-top-color: #fff;
    }
    img {
      width: 150px;
      height: 150px;
      vertical-align: middle;
    }
  }
}
</style>
