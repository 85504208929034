<!-- MessageContainer.vue -->
<template>
  <div class="message-container">
    <transition-group name="message">
      <message-item
        v-for="msg in messages"
        :key="msg.id"
        :msg="msg"
        @close="closeMessage(msg.id)"
      ></message-item>
    </transition-group>
  </div>
</template>

<script>
import MessageItem from './MessageItem.vue'

export default {
  name: 'MessageContainer',
  components: {
    MessageItem
  },
  data() {
    return {
      messages: []
    }
  },
  mounted() {
    this.startMessageTimer()
  },
  methods: {
    startMessageTimer() {
      this.timer = setInterval(() => {
        this.checkMessageExpiration()
      }, 1000)
    },
    checkMessageExpiration() {
      const now = new Date().getTime()
      this.messages = this.messages.filter(msg => now - msg.createTime < msg.duration)
    },
    addMessage(msg) {
      this.messages.push(msg)
    },
    closeMessage(id) {
      this.messages = this.messages.filter(msg => msg.id !== id)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style>
.message-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.message-enter-active,
.message-leave-active {
  transition: all 0.3s;
}

.message-enter,
.message-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>