<template>
  <div>
    <MessageArea
      title="评论区"
      :subTitle="`共${data.total}条评论`"
      :list="data.rows"
      :isListLoading="isLoading"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import MessageArea from '@/components/MessageArea'
import fetchData from '@/mixins/fetchData'
import { getComments,postComment } from '@/api/blog'
export default {
  mixins: [fetchData({ total: 0, rows: [] })],
  components: {
    MessageArea
  },
  data () {
    return {
      page: 1,
      limit: 10,
    }
  },
  methods: {
    async fetchData () {
      return await getComments(this.page, this.limit)
    },
    async handleSubmit (content,callback) {
      // 提交评论
      const res = await postComment({
        blogId: this.$route.params.id,
        ...content
      })
      if(res){
        this.data.rows.unshift(res)
        this.total++
        callback('评论成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>