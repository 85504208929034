<template>
  <div class="ImageLoader-container">
    <img
    v-if="!everythingLoaded"
    class="ImageLoader-placeholder"
    :src="placeholder"
    />
    <img
      :src="src"
      :style="{
        transition: `opacity ${duration}ms`,
        opacity: loaded ? 1 : 0,
      }"
      @load="handleLoad"
    />
  
  </div>
</template>

<script>
  export default {
    name: 'ImageLoader',
    props: {
      src: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: true,
      },
      duration: {
        type: Number,
        default: 500,
      },
    },
    data () {
      return {
        loaded: false,
        everythingLoaded: false,
      }
    },
    methods: {
      handleLoad () {
        this.loaded = true
        setTimeout(() => {
          this.everythingLoaded = true
          this.$emit('load')
        }, this.duration)
      },
      
    },
  }
</script>

<style lang="less" scoped>
@import url(~@/styles/mixin.less);
.ImageLoader-container{
  position: relative;
  width: 100%;
  height: 100%;
  img {
    .self-fill();
    object-fit: cover;
  }
  .ImageLoader-placeholder {
    filter: blur(2vm);
  }
}
</style>