<!-- App.vue -->
<template>
  <!-- <Layout>
    <BlogList />
    <template #right>
      <BlogCategory />
    </template>
  </Layout> -->
  <XScroll>
    <div class="width">1</div>
    <div class="width">2</div>
    <div class="width">3</div>
    <div class="width">4</div>
  </XScroll>
</template>

<script>
// import Layout from '@/components/Layout'
// import BlogList from './components/BlogList';
// import BlogCategory from './components/BlogCategory';
import XScroll from './components/XScroll';

export default {
  name: 'App',
  components: {
    XScroll
    // Layout,
    // BlogList,
    // BlogCategory
  },
  methods: {
  }
}
</script>
<style>
.width{
  width: 100px;
  height: 100px;
  background: red;
  margin: 10px;
}
</style>