export default {
  data(){
    return {
      msg:{
        value:'hello world'
      },
    }
  },
  methods: {
    setMsg(){
      this.msg.value='hello ceshi';
    }
  }
}