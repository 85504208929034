<template>
  <div class="blog-toc-container">
    <h2>文章目录</h2>
    <RightList :list="tocWithAnchor" @select="handleClick" />
  </div>
</template>

<script>
import RightList from './RightList'
  export default {
    name: 'BlogTOC',
    props: {
      toc: {
        type: Array,
        default: () => []
      }
    },
    components: {
      RightList
    },
    data () {
      return {
        activeAnchor: ''
      }
    },
    computed: {
      tocWithAnchor () {
        const getTOC = (toc = []) => {
          return toc.map(item => {
            return {
              ...item,
              isSelect: item.anchor === this.activeAnchor,
              children: getTOC(item.children)
            }
          })
        }
        return getTOC(this.toc)
      },
      doms() {
        return document.querySelectorAll('.blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6')
      }
    },
    methods: {
      handleClick (item) {
        location.hash = item.anchor
      },
      setSelect() {
      }
    }
  }
</script>

<style lang="less" scoped>
.blog-toc-container {
  h2 {
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 1em;
    padding: 0;
  }
}
</style>