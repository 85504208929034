import { render, staticRenderFns } from "./XScroll.vue?vue&type=template&id=4ddc4435&scoped=true"
import script from "./XScroll.vue?vue&type=script&lang=js"
export * from "./XScroll.vue?vue&type=script&lang=js"
import style0 from "./XScroll.vue?vue&type=style&index=0&id=4ddc4435&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddc4435",
  null
  
)

export default component.exports