import './mock'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './styles/global.less'
import router from './router'
import utils from './utils'
import vLoading from './directives/loading'
Vue.prototype.$showMessage = utils.showMessage

Vue.directive('loading', vLoading);
Vue.use(Vuex)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
