import axios from "axios";

// 创建axios实例
const service = axios.create();
//响应拦截
service.interceptors.response.use((res) => {
  if (res.data.code !== 0) {
    this.$showMessage({ content: res.data.msg, type: "error" });
    return null;
  }
  return res.data.data;
});

export default service;