<template>
  <div class="home-container" 
  ref="home"
  @wheel="handleWhee"
  v-loading="isLoading"
  >
    <ul class="carousel-container" :style="{
      marginTop: `-${marginTop}px`
    }"
    >
      <li v-for="banner in data" :key="banner.id">
        <Carouselitem :banner="banner" />
      </li>
    <h2 style="color: rgb(255, 255, 255);position: fixed;top: 0;z-index: 1;">{{ msg.value }}</h2>
    </ul>
    <div v-show="index > 0" class="icon icon-up" @click="switchBanner(--index)">
      <Icon type="arrowUp"></Icon>
    </div>
    <div v-show="index < data.length - 1" class="icon icon-down" @click="switchBanner(++index)">
      <Icon type="arrowDown"></Icon>
    </div>
    <ul class="indicator">
      <li v-for="(banner, i) in data" :key="banner.id" :class="{active: i === index}" @click="switchBanner(i)"></li>
    </ul>
  </div>
</template>

<script>
import { getBanner } from '@/api/banner'
import Carouselitem from './Carouselitem';
import Icon from '@/components/Icon'
import fetchData from '@/mixins/fetchData';
import ceshi from '@/mixins/ceshi';

  export default {
    mixins: [fetchData([]),ceshi],
    components: {
      Carouselitem,
      Icon,
    },
    data() {
      return {
        data: [],
        index: 0, // 当前显示的图片索引
        containerHeight: 0, // 容器高度
        switching: false, // 是否正在切换,
      }
    },
    computed: {
      marginTop() {
        return this.containerHeight * this.index
      }
    },
    mounted() {
      this.containerHeight = this.$refs.home.clientHeight
      window.addEventListener('resize', this.handResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handResize)
      // this.stopSwitch()
    },
    methods: {
      async fetchData() {
        return await getBanner()
      },
      switchBanner(index) {
        if (index < 0) {
          index = this.data.length - 1
        } else if (index > this.data.length - 1) {
          index = 0
        }
        this.index = index
      },
      handleWhee(event) {
        if (this.switching ) return
        this.switching = true
        // this.stopSwitch()
        if (event.deltaY < 0) {
          this.switchBanner(--this.index)
        } else{
          this.switchBanner(++this.index)
        }
        setTimeout(() => {
          this.switching = false
          // this.autoSwitch()
        }, 500)
      },
      handResize() {
        this.containerHeight = this.$refs.home.clientHeight
      },
      /* autoSwitch() {
        this.switchTimer = setInterval(() => {
          this.switchBanner(++this.index)
        }, this.switchInterval)
      },
      stopSwitch() {
        clearInterval(this.switchTimer)
      } */
    }
  }
</script>

<style lang="less" scoped>
@import url('~@/styles/mixin.less');
@import url('~@/styles/val.less');
.home-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: @dark;
  overflow: hidden;
  ul {
    margin: 0;
    li {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .carousel-container {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    li {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
      cursor: pointer;
      &.active {
        z-index: 1;
      }
    }
  }
  .icon {
    .self-center();
    font-size: 20px;
    color: @gray;
    @gap: 25px;
    top: @gap;
    cursor: pointer;
    &-up {
      --move:-10px;
      animation: alternate infinite 2s jump;
    }
    &-down {
      --move:10px;
      top: auto;
      bottom: @gap;
      animation: alternate infinite 2s jump;
    }
    @keyframes jump {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(var(--move));
      }
      100% {
        transform: translateY(0);
      }
      
    }
  }
  .indicator {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @words;
      border:1px solid #fff;
      margin: 5px 0;
      &.active {
        background-color: #fff;
      }
    }
  }
}
</style>