<template>
    <layout>
      <div class="main-container" v-loading="isLoading">
        <BlogDetail v-if="data" :blog="data" />
        <BLogComment v-if="!isLoading" />
      </div>
      <template #right>
        <div class="right-container" v-loading="isLoading">
          <BlogTOC v-if="data" :toc="data.toc" />
        </div>
      </template>
    </layout>
</template>

<script>
import fetchData from '@/mixins/fetchData'
import { getBlog } from '@/api/blog'
import layout from '@/components/Layout'
import BlogDetail from './components/BlogDetail'
import BlogTOC from './components/BlogTOC'
import BLogComment from './components/BLogComment'

  export default {
    name: 'Detail',
    mixins: [fetchData(null)],
    components: {
      layout,
      BlogDetail,
      BlogTOC,
      BLogComment
    },
    data () {
      return {
      }
    },
    methods: {
      async fetchData () {
        return await getBlog(this.$route.params.id)
      }

    }
  }
</script>

<style lang="less" scoped>
.main-container{
  position: relative;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 20px;
  scroll-behavior: smooth;
}
.right-container{
  position: relative;
  height: 100%;
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
}
</style>