<template>
  <i :class="iconClass"></i>
</template>

<script>
const classMap = {
  home: "iconzhuye",
  success: "iconzhengque",
  error: "iconcuowu",
  close: "iconguanbi",
  warn: "iconjinggao",
  info: "iconxinxi",
  blog: "iconblog",
  code: "iconcode",
  about: "iconset_about_hov",
  weixin: "iconweixin",
  mail: "iconemail",
  github: "icongithub",
  qq: "iconsign_qq",
  arrowUp: "iconiconfonticonfonti2copy",
  arrowDown: "iconiconfonticonfonti2",
  empty: "iconempty",
  chat: "iconliuyan",
}
export const types = Object.keys(classMap)
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      classMap
    }
  },
  computed: {
    iconClass () {
      return `iconfont ${this.classMap[this.type]}`
    }
  }
}
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_2164449_nalfgtq7il.css");
.iconfont{
  font-size: inherit;
  color:inherit;
}
</style>