<template>
  <div class="Menu-container">
    <router-link
      class="Menu-item"
      v-for="item in MenuData"
      :exact="item.exact"
      :key="item.id"
      :to="{ name: item.text }"
      active-class="active"
      exact-active-class=""
    >
      <Icon :type="item.type" />
      <label>{{ item.text }}</label>
    </router-link>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
export default {
  components: {
    Icon,
  },
  data () {
    return {
      MenuData: [
        {
          id: 1,
          type: 'home',
          text: '首页',
          exact: true,
        },
        {
          id: 2,
          type: 'blog',
          text: '文章',
          exact: false,
        },
        {
          id: 3,
          type: 'about',
          text: '关于我',
          exact: true,
        },
        {
          id: 4,
          type: 'code',
          text: '项目&效果',
          exact: true,
        },
        {
          id: 5,
          type: 'chat',
          text: '留言板',
          exact: true,
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import url(~@/styles/val.less);
.Menu-container {
  .Menu-item {
    display: block;
    text-align: left;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    font-size: 16px;
    cursor: pointer;
    color: @lightWords;
    .iconfont {
      margin-right: 5px;
    }
    &:hover {
      color: #fff;
      background: @gray;
    }
    &.active {
      background: @gray;
      color: #fff;
    }
  }
}
</style>
