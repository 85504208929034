import Home from '@/views/Home'
import About from '@/views/About'
import Blog from '@/views/Blog'
import BlogDetail from '@/views/Blog/Detail'
import Project from '@/views/Project'
import Message from '@/views/Message'

export default [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  {
    path: '/about',
    name: '关于我',
    component: About
  },
  {
    path: '/article',
    name: '文章',
    component: Blog
  },
  {
    path: '/article/cate/:categoryId',
    name:'文章分类',
    component: Blog
  },
  {
    path: '/article/:id',
    name:'文章详情',
    component: BlogDetail
  },
  {
    path: '/project',
    name: '项目&效果',
    component: Project
  },
  {
    path: '/message',
    name: '留言板',
    component: Message
  }
]