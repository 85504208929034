<!-- MessageItem.vue -->
<template>
  <div class="message-item" :class="msg.type">
    <span>{{ msg.content }}</span>
    <span class="close" @click="handleClose">×</span>
  </div>
</template>

<script>
export default {
  name: 'MessageItem',
  props: {
    msg: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.message-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
}

.message-item.success {
  background-color: #f0f9eb;
  color: #67c23a;
}

.message-item.error {
  background-color: #fef0f0;
  color: #f56c6c;
}

.message-item .close {
  cursor: pointer;
}
</style>