<template>
  <div class="SiteAside-container">
    <Avatar
      url="https://picsum.photos/300/300"
      :size="180"
    ></Avatar>
    <h1>阿博</h1>
    <Menu></Menu>
    <Contact></Contact>
  </div>
</template>

<script>
  import Avatar from '../Avatar'
  import Contact from './Contact'
  import Menu from './Menu'
  export default {
    components: {
      Contact,
      Menu,
      Avatar,
    },
  }
</script>

<style lang="less" scoped>
@import url(~@/styles/val.less);
.SiteAside-container{
  overflow-y: auto;
  width: 250px;
  background-color: @dark;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  //隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }

  .Avatar-container{
    margin:10px auto 20px;
  }
  .Contact-container{
    margin-bottom: 20px;
  }
  .Menu-container{
    margin-bottom: 20px;
  }
}
h1{
  color: @lightWords;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
</style>