const modulesFiles = require.context('./', true, /^(?!.*index\.js).*\.js$/)
// 使用reduce方法遍历文件上下文模块
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // 获取模块名称
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  modules[moduleName] = modulesFiles(modulePath).default
  return modules
}, {})
// 导出所有模块
export default modules

