<template>
  <div
    class="carousel-item-container"
    ref="container"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="carousel-img"
      ref="inner"
      :style="imagePosition"
    >
      <ImageLoader
        @load="showWords"
        :src="banner.bigImg"
        :placeholder="banner.midImg"
      />
    </div>
    <h2
      class="title"
      ref="title"
      @click="setMsg"
    >{{ banner.title }}{{ msg.value }}</h2>
    <h3
      class="desc"
      ref="desc"
    >{{ banner.description }}</h3>
  </div>
</template>

<script>
import ImageLoader from '@/components/ImageLoader'
import ceshi from '@/mixins/ceshi'
export default {
  mixins: [ceshi],
  components: {
    ImageLoader
  },
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      titleWidth: 0,
      descWidth: 0,
      containerSize: null,//外层容器尺寸
      innerSize: null, //内层容器尺寸
      mouse:null//鼠标位置
    }
  },
  computed: {
    imagePosition () {
      //计算图片的位置相对于鼠标的位置
      if (this.containerSize && this.innerSize && this.mouse) {
        //计算图片的位置
        const left = (this.innerSize.width - this.containerSize.width) * (this.mouse.x / this.containerSize.width) 
        const top = (this.innerSize.height - this.containerSize.height) * (this.mouse.y / this.containerSize.height)
        return {transform: `translate(${-left}px,${-top}px)`}
       
      }
    }
  },
  mounted () {
    this.titleWidth = this.$refs.title.clientWidth
    this.descWidth = this.$refs.desc.clientWidth
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    //初始化鼠标位置
    this.mouse = {
      x: this.containerSize.width / 2,
      y: this.containerSize.height / 2
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    showWords () {
      const title = this.$refs.title,
        desc = this.$refs.desc
      title.style.opacity = 1
      title.style.width = 0
      title.style.transition = '1s'
      //强制重绘
      title.offsetWidth
      title.style.width = this.titleWidth + 'px'
      desc.style.opacity = 1
      desc.style.width = 0
      desc.style.transition = '2s 1s'
      desc.offsetWidth
      desc.style.width = this.descWidth + 'px'
      // requestAnimationFrame(() => {
      //   title.style.width = this.titleWidth + 'px'
      //   desc.style.width = this.descWidth + 'px'
      // })
    },
    handleResize () {
      this.containerSize = {
        width: this.$refs.container.clientWidth,
        height: this.$refs.container.clientHeight
      }
      this.innerSize = {
        width: this.$refs.inner.clientWidth,
        height: this.$refs.inner.clientHeight
      }
    },
    handleMouseMove (e) {
      //鼠标的位置相对容器的位置
      const rect = this.$refs.container.getBoundingClientRect()
      this.mouse = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      }
    
    },
    handleMouseLeave () {
      this.mouse = {
        x: this.containerSize.width / 2,
        y: this.containerSize.height / 2
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url(~@/styles/mixin.less);
@import url('~@/styles/val.less');

.carousel-item-container {
  position: relative;
  height: 100%;
  color: #fff;
  overflow: hidden;
  .carousel-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    height: 110%;
    transition:transform 0.3s;
  }
}
.title,
.desc {
  opacity: 0;
  position: absolute;
  top: calc(50% - 40px);
  left: 30px;
  letter-spacing: 3px;
  font-size: 2em;
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0, 5), -1px 0 0 rgba(0, 0, 0, 0, 5),
    0 1px 0 rgba(0, 0, 0, 0, 5), 0 -1px 0 rgba(0, 0, 0, 0, 5);
  white-space: nowrap;
  overflow: hidden;
}
.desc {
  top: calc(50% + 20px);
  font-size: 1.5em;
  color: lighten(@gray, 20%);
}
</style>